import React from 'react';
import '@amzn/awsui-components-react/index.css';
import {Button} from '@amzn/awsui-components-react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './ResultsTable.css';
import CONSTANTS from '../../../utils/constants.js';
import API from '../../../utils/api'

const base64js = require('base64-js');
const JSZip = require('jszip');
const FileSaver = require('file-saver');
const xlsx = require('xlsx');


class ResultsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: [],
            allSelected: false,
            noDataText: CONSTANTS.noDataText,
            base64: "",
            reportLoading: false,
            pdfDownloading: false,
            reportLoadingError: false,
            addDetailsInReport: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.updateState = this.updateState.bind(this);
        this.createReport = this.createReport.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
        this.fetchInvoiceDocument = this.fetchInvoiceDocument.bind(this);
        this.fetchSingleInvoiceDocument = this.fetchSingleInvoiceDocument.bind(this);
        this.exportZip = this.exportZip.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props) != JSON.stringify(prevProps)) {
            this.updateState("allSelected", false); 
            this.updateState("checked", []); 
        }
      }
      		  
    updateState(varName, val){
        this.setState({[varName]: val});
    }

    static defaultProps = {}

    async createReport() {
        var selectedInvoices = [];
        var excelRows = [];
        this.setState({reportLoading: true})
        for (var i = 0; i < this.props.data.length; i++) {
            if (this.state.checked[i]) {
                selectedInvoices.push(this.props.allData[i].invoiceId);
            }
        }
        if(selectedInvoices.length === 0) {
            this.setState({reportLoading: false});
            return;
        }
        var reportOffset = this.state.addDetailsInReport? 50: 200;
        for (var i = 0; i < selectedInvoices.length; i += reportOffset) {
            var reportInput = {invoiceIdList: selectedInvoices.slice(i, i + reportOffset), addDetails: this.state.addDetailsInReport};
            await API.generateReport(reportInput)
                .then(async (data) => {
                    var workbook = xlsx.read(data.workbook, {type:'base64'});
                    var sheet = workbook.Sheets["Invoice Report"];
                    excelRows = excelRows.concat(xlsx.utils.sheet_to_json(sheet));
                })
                .catch(() => {
                    this.setState({reportLoading: false});
                    this.setState({reportLoadingError: true});
                })
            if (this.state.reportLoadingError) {
                this.setState({reportLoadingError: false});
                alert("Create report timed out, please try again");
                break;
            }
        }
        var reportWorkbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(reportWorkbook, xlsx.utils.json_to_sheet(excelRows), "Invoice Report");
        var base64String = xlsx.write(reportWorkbook, {bookType:"xlsx", type:'base64'});
        this.downloadReport(CONSTANTS.REPORT.FILENAME, base64String);
        this.setState({reportLoading: false});
    }

    downloadReport(fileName, dataURL) {
        var reportDownload = document.createElement("a");
        reportDownload.download = fileName;
        reportDownload.href = CONSTANTS.REPORT.DATA_URL_BASE + dataURL;
        document.body.appendChild(reportDownload);
        reportDownload.click();
        document.body.removeChild(reportDownload);
    }

    fetchSingleInvoiceDocument(nameIdMap) {
        var invoiceId;
        var fileName;
        for (fileName in nameIdMap) {
            invoiceId = nameIdMap[fileName];
        }
        var pdfDownloadRequest = {invoiceId: invoiceId};
        API.fetchInvoiceDocument(pdfDownloadRequest)
            .then((data) => {
                if (data.status === "Failed") throw new Error(data.errorMessage);
                var base64String = data.invoiceDocumentBase64;
                var base64XMl = data.invoiceXMLBase64;
                this.downloadSinglePDF(fileName, base64String);
                if( !!base64XMl ){
                    this.downloadSingleXML(fileName, base64XMl)
                }
                this.setState({pdfDownloading: false});
            })
            .catch((error) => {
                this.setState({pdfDownloading: false});
                alert(error);
            })
    }

    downloadSinglePDF(fileName, base64String) {
        if (base64String == null) {
            return;
        }
        var pdfByte = base64js.toByteArray(base64String);
        var blob = new Blob([pdfByte], {type: 'application/pdf'});
        var pdfURL = URL.createObjectURL(blob);
        var pdfDownload = document.createElement("a");
        pdfDownload.download = fileName;
        pdfDownload.href = pdfURL;
        document.body.appendChild(pdfDownload);
        pdfDownload.click();
        document.body.removeChild(pdfDownload);
    }

    downloadSingleXML(fileName, base64String) {
        if (base64String == null) {
            return;
        }
        var xmlByte = base64js.toByteArray(base64String);
        var blob = new Blob([xmlByte], {type: 'application/xml'});
        var xmlURL = URL.createObjectURL(blob);
        var xmlDownload = document.createElement("a");
        xmlDownload.download = fileName;
        xmlDownload.href = xmlURL;
        document.body.appendChild(xmlDownload);
        xmlDownload.click();
        document.body.removeChild(xmlDownload);
    }

    downloadPDF(invoiceId) {
        console.log("Trying to download: " + invoiceId);
        var pdfDownloadRequest = {invoiceId: invoiceId};
        return API.fetchInvoiceDocument(pdfDownloadRequest)
            .then((data) => {
                if (data.status === "Failed") throw new Error(data.errorMessage);
                var pdfByte = base64js.toByteArray(data.invoiceDocumentBase64);
                var pdfBlob = new Blob([pdfByte], {type: 'application/pdf'});

                if( !!data.invoiceXMLBase64){
                    var xmlByte = base64js.toByteArray(data.invoiceXMLBase64);
                    var xmlBlob = new Blob([xmlByte], {type: 'application/xml'}) ;
                }
                console.log('Info: generated blob for ', invoiceId);
                return {
                    pdfBlob: pdfBlob,
                    xmlBlob: xmlBlob
                }
            })
            .catch((error) => {
                console.log(error.message);
                throw error;
            })
    };

    async downloadByGroup(nameIdMaps) {
        var nameBlobMaps = {};
        var failureReport = "";
        var failureReportBlob;
        for (var name in nameIdMaps) {
            await this.downloadPDF(nameIdMaps[name])
                .then((blob) => {
                    nameBlobMaps[name] = blob.pdfBlob

                    if(!!blob.xmlBlob){
                        nameBlobMaps[name + "_xml"] = blob.xmlBlob
                    }
                })
                .catch((error) => {
                    var message = 'Failed to download invoice ' + name + ': ' + error + '\n';
                    failureReport += message;
                })
        }
        failureReportBlob = new Blob([failureReport], {type: 'text/plain'});
        nameBlobMaps["report.text"] = failureReportBlob;
        return nameBlobMaps;
    };

    exportZip(nameBlobMaps) {
        const zip = JSZip();
        for (var filename in nameBlobMaps) {
            if (filename === "report.text") {
                zip.file(filename, nameBlobMaps[filename]);
            } else if (filename.includes("xml")) {
                zip.file(filename + ".xml", nameBlobMaps[filename]);
            } else {
                zip.file(filename + ".pdf", nameBlobMaps[filename]);
            }
        }
        zip.generateAsync({type: 'blob'}).then(zipFile => {
            this.setState({pdfDownloading: false});
            return FileSaver.saveAs(zipFile, `invoicePDFs.zip`);
        });
    }

    async downloadZip(nameIdMaps) {
        this.exportZip(await this.downloadByGroup(nameIdMaps));
    }

    fetchInvoiceDocument() {
        this.setState({pdfDownloading: true})
        if (this.props.data.length === 0 || this.props.data.length === null) {
            setTimeout(() => {
                this.setState({pdfDownloading: false})
            }, 500);
            return;
        }
        var nameIdMaps = {};
        for (var i = 0; i < this.props.data.length; i++) {
            if (this.state.checked[i] && this.props.allData[i].status === 'Complete') {
                console.log(this.props.allData[i].status)
                const invoiceId = this.props.allData[i].invoiceId;
                const vatId = this.props.allData[i].invGenericValueTax;
                const invoiceNumber = this.props.allData[i].invoiceNumber;
                let date_time = this.props.allData[i].invoiceHeaderDate;
                let fileName;
                let date = '';
                let time = '';
                let dateTimeSeparator = 'T';

                if (this.props.allData[i].genericAddressCountryName === 'Saudi Arabia') {
                    if (date_time.length >= 10) {
                        date = date_time.substring(0,10).replace(/-/g, '');
                    }
                    if (date_time.length >= 19) {
                        time = date_time.substring(11,19).replace(/:/g, '');
                    }
                    // Expected Output:- 3xxxxxxxxx1xxx3_20210526T132400_ICINV-B170-00001
                    fileName = vatId + '_' + date + dateTimeSeparator + time + '_' + invoiceNumber;
                } else {
                    // Expected Output:- ICINV-B170-00001
                    fileName = invoiceNumber;
                }
                nameIdMaps[fileName] = invoiceId;
            }
        }

        if (this.isSingleInvoice(nameIdMaps)) {
            return this.fetchSingleInvoiceDocument(nameIdMaps);
        } else if (!this.isEmpty(nameIdMaps)) {
            return this.downloadZip(nameIdMaps);
        }
        this.setState({pdfDownloading: false});
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    isSingleInvoice(map) {
        var len = 0;
        for (var count in map) {
            console.log(count);
            len++;
        }
        return len === 1;
    }

    handleClick(rowIndex) {
        var newChecked = [];
        if (this.state.checked.length === 0) {
            for (var i = 0; i < this.props.data.length; i++) {
                newChecked.push(false);
            }
        } else {
            newChecked = this.state.checked.slice();
        }
        newChecked[rowIndex] = !newChecked[rowIndex];
        this.setState({checked: newChecked});
    }

    selectAll() {
        const select = !this.state.allSelected;
        var newChecked = [];
        for (var i = 0; i < this.props.data.length; i++) {
            newChecked.push(select);
        }
        this.setState({allSelected: select});
        this.setState({checked: newChecked});
    }

    handleDetailedReportOnChange() {
        this.setState(prevState => ({addDetailsInReport: !prevState.addDetailsInReport}))
    }

    render() {

        const columns2 = [{
            Header: <input type="checkbox"
                           checked={this.state.allSelected}
                           onChange={() => this.selectAll()}
            />,
            Cell: row => (<input
                    type="checkbox"
                    checked={this.state.checked[row.index]}
                    onChange={() => this.handleClick(row.index)}
                />
            ),
            sortable: false,
            width: 50
        },
            {
                Header: "#",
                accessor: "number",
                width: 40
            },
            {
                Header: "Invoice System",
                accessor: "invoiceSystem"
            },
            {
                Header: "Invoice Source",
                accessor: "invoiceSource"
            },
            {
                Header: "Invoice Status",
                accessor: "status"
            },
            {
                Header: "Source Invoice Number",
                accessor: "invoiceNumber"
            },
            {
                Header: "Invoice Id",
                accessor: "invoiceId"
            },
            {
                Header: "Company From",
                accessor: "genericParty"
            },
            {
                Header: "Company To",
                accessor: "custParty"
            },
            {
                Header: "Einvoice",
                accessor: "eInvoice"
            },
            {
                Header: "Self Billing Status",
                accessor: "selfBillingStatus"
            },
            {
                Header: "Self Billing EInvoice",
                accessor: "selfBillingEInvoiceEligible"
            }
        ]

        return (
            <React.Fragment>
                <div className="awsui-util-container">
                    <div className="awsui-util-container-header">
                        <h2>Search Results</h2>
                    </div>
                    <ReactTable
                        class="relationships"
                        data={this.props.data}
                        defaultPageSize={20}
                        columns={columns2}
                        minRows={8}
                        loading={this.props.isLoadingInvoices}
                        loadingText="Retrieving Invoices... "
                        noDataText={this.state.noDataText}
                        showPagination={true}
                    >
                    </ReactTable>
                    <div className="awsui-util-container-footer"></div>
                    <div className="awsui-util-action-stripe-group">
                        <Button onClick={() => {
                            this.fetchInvoiceDocument()
                        }}
                                loading={this.state.pdfDownloading}>Download</Button>
                        <Button onClick={() => {
                            this.createReport()
                        }}
                                loading={this.state.reportLoading}>Create Report</Button>
                        <div className='detailReportDiv'>
                            <label className='report-checkbox-label'>
                                <input type="checkbox"
                                checked={this.state.reportDetails}
                                onChange={() => this.handleDetailedReportOnChange()}
                                /> Add Details
                            </label>
                        </div>
                        <div class="detailReportInfo">This might take longer to download</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ResultsTable;